import React from 'react';
import Slider from "react-slick";
import Img1 from "../../images/img1.jpg";
import Img2 from "../../images/img2.jpg";
import Img3 from "../../images/img3.jpg";
import "slick-carousel/slick/slick.css";
import { Link } from "gatsby";
import DateFormat from "../format-date";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";

const ImageSlider = (props) => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        centerPadding: '240px',
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerPadding: '192px',
                }
            },
            {
                breakpoint: 1293,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerPadding: '140px',
                }
            },
            {
                breakpoint: 991,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    centerPadding: '0px',
                }
            }
        ]
    };

    return (
        <div className="news-slider">
            <Slider {...settings}>
                {props.newslist && props.newslist.edges.map(({ node }, key) => {
                    let mycatslug = "news/"
                    let mycatslugchk = node && node.Select_Categories && node.Select_Categories.length > 0 && node.Select_Categories[0].URL
                    if (mycatslugchk) {
                        mycatslug = mycatslugchk + "/"
                    }
                    let processedImages = JSON.stringify({});
                    if (node?.imagetransforms?.Intro_Image_Transforms) {
                        processedImages = node.imagetransforms.Intro_Image_Transforms;
                    }
                    const image_url = node?.Intro_Image?.internal?.description ? node.Intro_Image.internal.description.replace("File ", "").replace('"', '').replace('"', '') : '';
                    return <>
                        <div className="news-block text-center">
                            <figure className="news-img">
                                <ImageTransform imagesources={image_url} renderer="srcSet" imagename="news-events.Intro_Image.sliderimg" attr={{ alt: node.Title + ' - Orlando Reid' }} imagetransformresult={processedImages} id={node.strapiId} />
                            </figure>
                            <div className="news-title">
                                <Link to={`/about-us/stories/${mycatslug}${node.URL}/`}>
                                    <span><DateFormat date={node.News_Date} /></span>
                                </Link>
                                <Link to={`/about-us/stories/${mycatslug}${node.URL}/`}>
                                    <h3>{node.Title}</h3>
                                </Link>
                            </div>
                        </div>
                    </>
                })}

            </Slider >
        </div>


    )

}

export default ImageSlider;
