import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap';
import "animate.css/animate.css";
// import ScrollAnimation from 'react-animate-on-scroll';
import DropDown from './Dropdown/dropdown';
import ReactMarkdown from "react-markdown/with-html"
import GetCTA from "./get-cta"
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import Img from 'gatsby-image';
import { useLocation } from "@reach/router";
import { VideoPlayButton } from '../components/icon';
import $ from 'jquery'
import ImageTransform from "./common/ggfx-client/module/components/image-transform";

const RightTile = (props)=>{
  //console.log("righttilee",props)

  let proptitle = '';
  if (props.Title != null && props.Title != "") {
      proptitle = props.Title.replace(/[^\w\s]/gi, '').replace(/\s/g, '');
  }
   const [active, setActive] = useState('+ Read More');
  const handleAccordionClick = (event) => {
    var thishash = '#'+event;
    $('html, body').animate({
      scrollTop: $(thishash).offset().top - 70
    }, 1000);
    
    if (active === '+ Read More') {
        setActive('- Read Less')
    } else {
        setActive('+ Read More')
    }
}

if (props.Content && props.Content.length < 600) {
    var long_desc_first_part = props.Content;
}
else {
    var long_desc_first_part = props.Content ? props.Content.split(/\s+/).slice(0, 60).join(" ") : '';
}

  const location = useLocation();
  const thePath = location.pathname
  const PageSlug = thePath.substring(thePath.lastIndexOf('/') + 1) + ' '
  if(props.TileVideo != null) {
    var videoid = getVideoId(props.TileVideo);
  }
  const [showVideo, setShowVideo] = React.useState(false);
  const [,setPlay] = React.useState(false);
	const [] = useState(false)
  const [, setMypropertyValue] = useState(0);
  const trackerVideo = (event) => {    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': event,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
}
	useEffect(()=>{

		function isInViewport(el) {
		    let top = el.offsetTop;
  			let left = el.offsetLeft;
  			let width = el.offsetWidth;
  			let height = el.offsetHeight;

			  while(el.offsetParent) {
			    el = el.offsetParent;
			    top += el.offsetTop;
			    left += el.offsetLeft;
			  }

			  return (
			    top < (window.pageYOffset + window.innerHeight) &&
			    left < (window.pageXOffset + window.innerWidth) &&
			    (top + height) > window.pageYOffset &&
			    (left + width) > window.pageXOffset
			  );

		}

    

	},[]) 

    let processedImages = JSON.stringify({});
    if (props?.imagetransforms?.Tile_Image_Transforms) { 
        processedImages = props.imagetransforms.Tile_Image_Transforms;
    }
    const image_url = props.TileImg && props.TileImg.internal.description ? props.TileImg.internal.description.replace("File ", "").replace('"', '').replace('"', '') : '';
    
    const imagename = (props.appId ? `${props.appId}` : 'articles') + '.Tile_Image.commontile';


	return (<React.Fragment>
	    <section className="section-market section-tile" id={proptitle}>
        <Container className="market ">
          <Row className="align-items-lg-center flex-lg-row-reverse">
            <Col lg="6" className={`col-xl-7 imgsec ${props.Title.toLowerCase().replace(/\s/g, '')}`}>
            {/* <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} > */}

              <div className="vedio-card playing-video">
                <div className="tile-image-wrapper">
                    <ImageTransform imagesources={image_url} renderer="srcSet" imagename={imagename} attr={{ alt: `${PageSlug}${props.Title} - Orlando Reid` }} imagetransformresult={processedImages} id={props.AriticleId}/>
                </div>

              {props.TileVideo !=null && props.TileVideo !='' &&
                <button className="btn-play"
                  onClick = { () => {setPlay(true);setShowVideo(true)}}
                >
                  <VideoPlayButton />
                </button>
              }
              </div>
            {
              showVideo &&
              <YouTube
                video={videoid.id}
                autoplay
                onEnd={() => {setPlay(false);setShowVideo(false)}}
                modestBranding={1}
                onPlaying={trackerVideo(props.Title)}
              />
            }
            {/* </ScrollAnimation> */}
            </Col>
            <Col lg="6" className={`col-xl-5 textcont ${props.Title.toLowerCase().replace(/\s/g, '')}`}>
            {/* <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} > */}
              <div className="property-report">
                <span className="sub-title">{props.Title}</span>
                {long_desc_first_part != null && long_desc_first_part != "" &&
                  <>
                      {active === '- Read Less' ? <ReactMarkdown source={props.Content} allowDangerousHtml /> : <ReactMarkdown source={props.Content.length > 850 ? long_desc_first_part : props.Content} allowDangerousHtml /> }
                      {props.Content.length > 850 && !props.CTA_Label && <a href="javascript:;" className="read-more custom-link" onClick={() => handleAccordionClick(proptitle)}>{active}</a>}
                  </>
                }
                {/* {props.Label == 'Explore New Developments' || props.Label == 'Explore Brunswick Mill' || props.Label == "EXPLORE INVESTMENT GUIDES" || props.label == "Explore Urban Village" || props.label == "Read Full Article"?   
                   <Link className="btn btn-default" to={`${(props.Custom_CTA_URL) ? props.Custom_CTA_URL : "/property/developments/new-homes/for-sale/in-manchester/"}`}>{props.Label}</Link> :
                <GetCTA classname = "btn btn-default" link = {props.CTAUrl} label={props.Label}/>} */}
                {props.CustomUrl != null &&
                <Link className="btn btn-default" to={`${props.CustomUrl}/`}>{props.Label}</Link>
                }  
                {props.Custom_CTA_URL != null && props.Custom_CTA_URL != "" &&
                   <Link className="btn btn-default" to={`${(props.Custom_CTA_URL) ? props.Custom_CTA_URL : "/property/developments/new-homes/for-sale/in-manchester/"}`}>{props.Label}</Link>
                }
                {
                  props.CTAUrl != null &&  props.CTAUrl != "" &&
                  <GetCTA classname = "btn btn-default" link = {props.CTAUrl} label={props.Label}/>
                }


                {props.CTAdrodown != null && props.CTAdrodown != "" &&
                  <DropDown CTATitle={props.Label} data = {props.CTAdrodown} valueChange={setMypropertyValue}/>
                }

              </div>
              {/* </ScrollAnimation> */}
            </Col>
          </Row>
        </Container>
      </section>
      
	</React.Fragment>)

}

export default RightTile;