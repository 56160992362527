import React from "react"
import { useStaticQuery, graphql } from "gatsby";

const GoogleReviewsScore = (props) => {
    const latestReviewsScore = useStaticQuery(graphql`
        query {
            allStrapiGoogleReviewReviews {
                edges {
                    node {
                        id
                        starRating
                    }
                },
                pageInfo {
                    totalCount
                }
            }
        }
    `)
    const reviews = latestReviewsScore.allStrapiGoogleReviewReviews.edges;
    const reviewsCount = latestReviewsScore.allStrapiGoogleReviewReviews.pageInfo.totalCount;
    const wordToNumber = {'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5};
    let sumOfScores = 0;

    reviews.map((review) => {
        const reviewStringValue = review.node.starRating;
        sumOfScores += wordToNumber[reviewStringValue];
        return(sumOfScores)
    })

    const averageRating = Math.round(sumOfScores / reviewsCount * 10) / 10;

    return (
        <span>{averageRating}/5 Rating from {reviewsCount} Customer Reviews</span>
    );
}

export default GoogleReviewsScore
