import React, { useState, useEffect } from "react";
import { Dropdown } from 'semantic-ui-react'
import $ from "jquery"
import { openPopupWidget } from "react-calendly";

const DropDown = (props) => {
	const onClickCalendly = (url) => openPopupWidget({ url });

	useEffect(()=>{
		$('.drop-value').click(function() {
			$('.drop-list').toggleClass("active")
		})
	}, [])

	// useEffect(() => {
	// 	var x, i, j, l, ll, selElmnt, a, b, c;
	// 	x = document.getElementsByClassName("custom-select");
	// 	l = x.length;
	// 	for (i = 0; i < l; i++) {
	// 	  selElmnt = x[i].getElementsByTagName("select")[0];
	// 	  ll = selElmnt.length;
	// 	  a = document.createElement("DIV");
	// 	  a.setAttribute("class", "select-selected");
	// 	  a.setAttribute("href", selElmnt.options[selElmnt.selectedIndex].value);
	// 	  a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
	// 	  x[i].appendChild(a);
	// 	  b = document.createElement("DIV");
	// 	  b.setAttribute("class", "select-items select-hide");
	// 	  for (j = 1; j < ll; j++) {
	// 		c = document.createElement("DIV");
	// 		//c.innerHTML = selElmnt.options[j].innerHTML;
	// 		c.innerHTML = '<a href='+selElmnt.options[j].value+'>'+selElmnt.options[j].innerHTML+'</a>'
	// 		c.addEventListener("click", function(e) {
	// 			var y, i, k, s, h, sl, yl;
	// 			s = this.parentNode.parentNode.getElementsByTagName("select")[0];
	// 			sl = s.length;
	// 			h = this.parentNode.previousSibling;
	// 			for (i = 0; i < sl; i++) {
	// 			  if (s.options[i].innerHTML == this.innerHTML) {
	// 				s.selectedIndex = i;
	// 				h.innerHTML = this.innerHTML;
	// 				y = this.parentNode.getElementsByClassName("same-as-selected");
	// 				yl = y.length;
	// 				for (k = 0; k < yl; k++) {
	// 				  y[k].removeAttribute("class");
	// 				}
	// 				this.setAttribute("class", "same-as-selected");
	// 				break;
	// 			  }
	// 			}
	// 			h.click();
	// 		});
	// 		b.appendChild(c);
	// 	  }
	// 	  x[i].appendChild(b);
	// 	  a.addEventListener("click", function(e) {
	// 		  e.stopPropagation();
	// 		  closeAllSelect(this);
	// 		  this.nextSibling.classList.toggle("select-hide");
	// 		  this.classList.toggle("select-arrow-active");
	// 		});
	// 	}
	// 	function closeAllSelect(elmnt) {
	// 	  var x, y, i, xl, yl, arrNo = [];
	// 	  x = document.getElementsByClassName("select-items");
	// 	  y = document.getElementsByClassName("select-selected");
	// 	  xl = x.length;
	// 	  yl = y.length;
	// 	  for (i = 0; i < yl; i++) {
	// 		if (elmnt == y[i]) {
	// 		  arrNo.push(i)
	// 		} else {
	// 		  y[i].classList.remove("select-arrow-active");
	// 		}
	// 	  }
	// 	  for (i = 0; i < xl; i++) {
	// 		if (arrNo.indexOf(i)) {
	// 		  x[i].classList.add("select-hide");
	// 		}
	// 	  }
	// 	}
	// 	document.addEventListener("click", closeAllSelect);
	// }, [])

	const changeValue = (event) => {
		let val = event.target.value;
		if (typeof props.valueChange === "function")
			props.valueChange(val)
	}

	return (
		<>
		<div className="select-dropdwn">
			<div name="cars" id="cars" onChange={changeValue}>
			<div className="drop-value" value="Value My Property">{props.CTATitle}</div>
			{props.data.map((Stats, key) => {
				return <>
				<div className="drop-list" value={Stats.Dropdown_Value}>
					{Stats.Dropdown_Label=='in person valuation' &&
					<a href={`${Stats.Dropdown_Value}?type=inperson`}>
						{Stats.Dropdown_Label}
					</a>
					}
					{Stats.Dropdown_Label=='virtual valuation' &&
					<a onClick={() => onClickCalendly(Stats.Dropdown_Value)}  rel="noreferrer"   href="javascript:;">
						{Stats.Dropdown_Label}
					</a>
					}
					{Stats.Dropdown_Label=='Instant Valuation' &&
					<a target="_blank" href={Stats.Dropdown_Value}>
						{Stats.Dropdown_Label}
					</a>
					}
					{Stats.Dropdown_Label=='instruct us now' &&
					<a href={`${Stats.Dropdown_Value}`}>
						{Stats.Dropdown_Label}
					</a>
					}
				</div>
				</>
			})}  
			</div>
		</div>
	
	</>
	
	);

}




export default DropDown;