import { Container, Navbar, Nav } from 'react-bootstrap';
import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

// import 'bootstrap/dist/css/bootstrap.min.css';
const GetCTA = (props) => {

   const data = useStaticQuery(graphql`
    query CTAQuery {  
    allStrapiAllMenus(sort: {fields: Sort, order: ASC}) {
        edges {
          node {
            Label
            URL
            Show_In_Burger_Menu
            Button_Class
            main_parent {
              Label
              URL
            }
            sub_parent {
              Label
              URL
            }
          }
        }
      }     
    }
  `)
    return (
      (props.link != null &&
        <>
        {data.allStrapiAllMenus.edges.map(({node}, key) => {
          return<>
          {node.main_parent === null && node.sub_parent === null && node.URL == props.link.URL &&
          <Link to={`/${props.link.URL}/`} className={props.classname}>{props.label}</Link>
          }
          {node.main_parent != null && node.sub_parent === null && node.URL == props.link.URL &&
          <Link to={`/${node.main_parent.URL}/${props.link.URL}/`} className={props.classname}>{props.label}</Link>
          }
          {node.main_parent != null && node.sub_parent != null && node.Label == props.link.Label &&
          <Link to={`/${node.main_parent.URL}/${node.sub_parent.URL}/${props.link.URL}/`} className={props.classname}>{props.label}</Link>
          }
          </>
        })}
        </>
      )
  )
}

GetCTA.propTypes = {
  siteTitle: PropTypes.string,
  link: "",
}

GetCTA.defaultProps = {
  siteTitle: ``,
}

export default GetCTA
